import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import resolveLink from '~utils/resolveLink'
import { Link } from "gatsby"

import Section from '~components/global/section'
import Slider from '~components/global/slider'
import BlockContent from '@sanity/block-content-to-react'
import useBreakpoint from '~utils/useBreakpoint'
import moment from 'moment'

const ConditionalWrapper = ({ condition, linkWrapper, divWrapper, children }) => 
  condition ? divWrapper(children) : linkWrapper(children);

const FeaturedCatArticleSlide = ({ content, mobile }) => (
  <ConditionalWrapper
    condition={mobile}
    linkWrapper={children => <Link to={resolveLink(content)} className="featured-cat-article-slide">{children}</Link>} 
    divWrapper={children => <div className="featured-cat-article-slide">{children}</div>}
  > 
    {mobile ? 
      <Link to={resolveLink(content)} className="featured-cat-article-slide__image">
        <Img fluid={{...content?.headerImage?.asset?.fluid, aspectRatio: 1.4}} />
      </Link>
      :
      <div to={resolveLink(content)} className="featured-cat-article-slide__image">
        <Img fluid={{...content?.headerImage?.asset?.fluid, aspectRatio: 1.4}} />
      </div>
    }
    <div className="featured-cat-article-slide__text">
      <div className="featured-cat-article-slide__heading">
        <h4 className="s-caps">{content.publishedAt && moment(content.publishedAt).format('DD.MM.YY')}</h4>
        {mobile ? 
          <Link to={resolveLink(content)}><h3 className="m-journal">{content.title}</h3></Link>
          :
          <h3 className="m-journal">{content.title}</h3>
        }
      </div>
      <div className="featured-cat-article-slide__body">
        <BlockContent blocks={content._rawExcerpt} />
      </div>
      {mobile ? 
        <Link to={resolveLink(content)} className="pill pill--green">Read more <span className="pill__arrow">&rarr;</span></Link>
        :
        <div className="pill pill--green">Read more <span className="pill__arrow">&rarr;</span></div>
      }
    </div>
  </ConditionalWrapper>
)

const ArticleSlide = ({ content, mobile }) => {
  return(
    <ConditionalWrapper
      condition={mobile}
      linkWrapper={children => <Link to={resolveLink(content)} className="article-slide">{children}</Link>} 
      divWrapper={children => <div className="article-slide">{children}</div>}
    > 
      
      {mobile ? 
        <Link to={resolveLink(content)} className="article-slide__image">
          <Img fluid={{...content?.headerImage?.asset?.fluid && {...content?.headerImage?.asset?.fluid, aspectRatio: 1}}}/>
        </Link>
        :
        <div className="article-slide__image">
          <Img fluid={{...content?.headerImage?.asset?.fluid && {...content?.headerImage?.asset?.fluid, aspectRatio: 1}}}/>
        </div>
      }
      <div className="article-slide__text">
        <div className="article-slide__heading">
          <h4 className="s-caps">{content.publishedAt && moment(content.publishedAt).format('DD.MM.YY')}</h4>
          {mobile ? 
            <Link to={resolveLink(content)}><h3 className="m-journal">{content.title}</h3></Link>
            :
            <h3 className="m-journal">{content.title}</h3>
          }
        </div>
        <div className="article-slide__body">
          <p>
            {content.text}
          </p>
        </div>
        {mobile ? 
          <Link className="pill pill--solid-white green" to={resolveLink(content)} >Read more <span className="pill__arrow">&rarr;</span></Link>
          :
          <div className="pill pill--solid-white green">Read more <span className="pill__arrow">&rarr;</span></div>
        }
      </div>
    </ConditionalWrapper>
  )
}

const ArticleSlider = ({ title, slides, featuredCategory, backgroundColor, hideArrows }) => {
  const { lgdesktop, tablet, mobile } = useBreakpoint()
  return(
    <Section name={`article-slider ${featuredCategory ? `featured-category`: ``} ${backgroundColor ? `featured-category--`+ backgroundColor: ``}`}>
      <h3 className="l-journal">
        {title}
      </h3>
      <div className="article-slider__slider">
        <Slider slidesVisible={mobile ? 1 : (featuredCategory ? 3 : 2)} arrows={hideArrows ? false : "sans"} swipeIndicator={mobile} preventDrag={!mobile}>
          {slides && slides.map(s => (
            featuredCategory ?
            <FeaturedCatArticleSlide content={s} mobile={mobile} key={s.title} />
            :
            <ArticleSlide content={s} mobile={mobile}></ArticleSlide>
          ))}
        </Slider>
      </div>
    </Section> 
  )
}

export default ArticleSlider

import React from "react"
import Helmet from "react-helmet"
import useSiteSettings from "~utils/useSiteSettings"

const siteRoute = "https://au.podandparcel.com"

const SEO = ({
  pagePath,
  metaInfo,
}) => {
  const { siteTitle, siteTagline } = useSiteSettings()
  const title = metaInfo
    ? metaInfo.metaTitle
      ? metaInfo.metaTitle + ' | ' + siteTitle
      : siteTitle
    : siteTitle
  const metaDescription = metaInfo
    ? metaInfo.metaDescription
      ? metaInfo.metaDescription
      : siteTagline
    : siteTagline
  const ogImage = metaInfo
    ? metaInfo.openImage
      ? metaInfo.openImage.asset.url
      : ""
    : ""
  const twitterImage = metaInfo
    ? metaInfo.twitterImage
      ? metaInfo.twitterImage.asset.url
      : ""
    : ""
  const openTitle = metaInfo
    ? metaInfo.openTitle
      ? metaInfo.openTitle + ' | ' + siteTitle
      : title
    : title
  const openGraphDescription = metaInfo
    ? metaInfo.openGraphDescription
      ? metaInfo.openGraphDescription
      : metaDescription
    : metaDescription
  const twitterTitle = metaInfo
    ? metaInfo.twitterTitle
      ? metaInfo.twitterTitle
      : title
    : title
  const twitterDescription = metaInfo
    ? metaInfo.twitterDescription
      ? metaInfo.twitterDescription
      : metaDescription
    : metaDescription
  return (
    <Helmet title={title}>
      <html lang="en-AU" />
      <meta http-equiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta property="og:locale" content="en_AU" />
      <meta name="description" content={metaDescription} />
      <meta
        property="og:url"
        content={`${siteRoute}${pagePath ? pagePath : ""}`}
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={`${openTitle}`} />
      <meta property="og:site_name" content={`${siteTitle}`} />
      <meta property="og:description" content={openGraphDescription} />
      <meta name="twitter:site" content="@podandparcel" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${twitterTitle}`} />
      <meta name="twitter:image:src" content={twitterImage} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta
        name="twitter:url"
        content={`${siteRoute}${pagePath ? pagePath : ""}`}
      />
      <link rel="canonical" href={`${siteRoute}${pagePath ? pagePath : ""}`} />
      <link rel="alternate" hrefLang="en-US" href="https://us.podandparcel.com/" />
      <meta name="google-site-verification" content="zxHC8-xzyqL6pmld9lR2AUAHlD9lZ09ddfjvfqILzUI" />
      {/* <link rel="alternate" hrefLang="en-AU" href={`${siteRoute}${!pagePath || pagePath === '/' ? '' : pagePath}`} /> */}
    </Helmet>
  )
}


export default SEO

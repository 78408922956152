import React from 'react'
import { Link } from 'gatsby'
import Button from '~components/global/button'

const ArticleCard = ({ date, title, intro, image, url, largeButton }) => (
  <div className="article-card s-journal">
    <Link to={url} className="article-card__image">
      {image}
    </Link>
    <h6 className="s-caps">{date}</h6>
    <h3 className="m-journal">{title}</h3>
    <div>
      {intro}
    </div>
    {!largeButton &&
      <Link to={url} className="pill pill--solid-green">Read more <span className="pill__arrow">&rarr;</span></Link>
    }
    {largeButton &&
      <Button to={url} noMinWidth={true} color="green">
        read more
      </Button>
    }
  </div>
)

export default ArticleCard

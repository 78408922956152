import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import Layout from "~components/layout"
import SEO from "~components/seo"
import Img from "gatsby-image"
import BlockContent from '@sanity/block-content-to-react'
import resolveLink from '~utils/resolveLink'
import useBreakpoint from "~utils/useBreakpoint"

import TextImage from "~components/modules/textImage" 
import Section from "~components/global/section"
import ArticleCard from "~components/global/articleCard"
import Button from "~components/global/button"
import ArticleSlider from "~components/modules/articleSlider"
import Ticker from "~components/modules/ticker"
import TextIllo from "~components/modules/textIllo"

import moment from 'moment'

const BlogIndex = ({ data }) => {

  const selectedIds = [...data.sanityBlogIndex.pinnedArticles.map(a => a.id), data.sanityBlogIndex.featuredArticle.id]
  let allArticles = data.allSanityArticle.nodes
  allArticles = allArticles.filter(a => !selectedIds.includes(a.id))

  const fa = data.sanityBlogIndex.featuredArticle

  const indexData = data.sanityBlogIndex

  const { mobile, tablet } = useBreakpoint()

  const signUpRef = useRef()

  const scrollToSignUp = e => {
    e.preventDefault()
    let offset = 0 - (document.body.getBoundingClientRect().top - signUpRef.current.getBoundingClientRect().top)
    offset = offset - (tablet ? 50 : 80)
    window.scrollTo({top: offset, behavior: 'smooth'})
  }

  const highlight = props => {
    return (
      <span>
        {props.children}
      </span>
    )
  }

  const BlockRenderer = props => {
    const {style = 'normal'} = props.node;

    if (style === 'normal') {
      return <h3 className="l-sans">{props.children}</h3>
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
  }

  const getArticlesinCategory = category => {
    const array =  data?.allSanityArticle?.nodes.filter(article => {
      return article.categories.map(cat => cat.title).includes(category)
    })
    array.length = array.length = Math.min(array.length, 20);
    return array
  }

  return(
    <Layout>
      <SEO metaInfo={data.sanityBlogIndex._rawMeta} pagePath='/learn' />
      <Section name="blog-index-header">
      <div className="blog-index-header__title">
        <h1 className="l-sans"><BlockContent blocks={data.sanityBlogIndex._rawHeading}/></h1>
      </div>
      <div className="blog-index-header__illo">
        <Img fluid={data.sanityBlogIndex.illo && data.sanityBlogIndex.illo.asset.fluid} />
      </div>
      </Section>
      {fa &&
        <TextImage 
        textAlign={`left`} 
        flip={true} 
        imageFit={`cover`} 
        img={<Img fluid={fa.headerImage?.asset?.fluid }/>} 
        textBg={`$maroon`}
        >
          <h2>{fa.title}</h2>
          <BlockContent blocks={fa._rawExcerpt} />
          <Button arrow color={`pink`} to={resolveLink(fa)}>Read More</Button>
        </TextImage>
      }
      {indexData?.brewGuideTitle  &&
        <TextImage 
        textAlign={`left`} 
        flip={false} 
        imageFit={`cover`} 
        img={<Img fluid={indexData.brewGuideImage?.asset?.fluid }/>} 
        textBg={`cream`}
        >
          <h2>{indexData?.brewGuideTitle}</h2>
          <p>
            {indexData?.brewGuideText}
          </p>
          <Button arrow color={`green`} to={resolveLink(indexData?.brewGuideLink )}>Learn More</Button>
        </TextImage>
      }
      <ArticleSlider 
        title="Pinned Articles" 
        slides={data.sanityBlogIndex.pinnedArticles && data.sanityBlogIndex.pinnedArticles}
      />
      {data.sanityBlogIndex?.featuredCategories?.map((featuredCategory, i) => (
        <div key={featuredCategory.title}>
          <ArticleSlider 
            title={featuredCategory.title} 
            slides={getArticlesinCategory(featuredCategory.title)?.map(article => article)} 
            featuredCategory={true}
            backgroundColor={i % 2 === 0 ? `white`: `cream`}
            key={featuredCategory.title}
            hideArrows={getArticlesinCategory(featuredCategory.title)?.map(article => article).length <= 3} 
          >
        </ArticleSlider>
        </div>
      ))}
      <div ref={signUpRef}>
        <TextIllo 
          cta={{...data.sanityBlogIndex._rawNewsletterSignUp, _type: 'newsletterSignUp'}} 
          bg={data.sanityBlogIndex.backgroundType} 
          image={<Img fluid={data.sanityBlogIndex.signUpIllo  && data.sanityBlogIndex.signUpIllo.asset.fluid} />}
        >
          <BlockContent 
            blocks={data.sanityBlogIndex._rawNewsletterHeading} 
            serializers={{types: {block: BlockRenderer}, marks: {highlight}}} 
          />
        </TextIllo>
      </div>
    </Layout>
  )
}

export const query = graphql`
query {
  allSanityArticle(sort: {fields: publishedAt, order: ASC}) {
    nodes {
      _rawExcerpt
      _type
      id
      slug {
        current
      }
      headerImage {
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      title
      publishedAt
      categories {
        title
        slug {
          current
        }
      }
    }
  }
  sanityBlogIndex(id: {eq: "-c13cd2ca-b73f-5b9f-8980-2d0702456a86"}) {
    _rawMeta(resolveReferences: {maxDepth: 2})
    _rawHeading(resolveReferences: {maxDepth: 5})
    _rawNewsletterHeading(resolveReferences: {maxDepth: 5})
    _rawNewsletterSignUp(resolveReferences: {maxDepth: 5})
    _rawTickerText(resolveReferences: {maxDepth: 5})
    illo {
      asset {
        fluid {
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
          aspectRatio
        }
      }
    }
    featuredArticle {
      _rawExcerpt
      _type
      id
      slug {
        current
      }
      headerImage{
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      title
      publishedAt
    }
    brewGuideTitle
    brewGuideText
    brewGuideImage{
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    brewGuideLink{
      _type
      slug {
        current
      }
    }
    featuredCategories {
      title
      slug {
        current
      }
    }
    newsletterSignUp {
      _type
      buttonText
      successMessage
    }
    pinnedArticles {
      _rawExcerpt
      _type
      id
      slug {
        current
      }
      headerImage {
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      title
      publishedAt
    }
    signUpIllo {
      asset {
        fluid {
          aspectRatio
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
    title
    tickerBackground {
      hex
    }
    tickerHighlightColor {
      hex
    }
    tickerTextColor {
      hex
    }
  }
}
`

export default BlogIndex
